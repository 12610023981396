import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
    .course-slider-area {
        padding: 63px 0;

        .sec-title {
            h4 {
                color        : ${colors.darkblue};
                line-height  : 35px;
                font-weight  : 600;
                max-width    : 550px;
                margin       : auto;
                
                @media(max-width: 575px) {
                    margin-bottom: 15px;
                    font-size: 20px;
                }
            }
        }
        .card-sec{
            .icon-blk{
                border-radius:50%;
                width:fit-content;
                color:${colors?.darkblue};

               
            }
            h5{
                color:${colors?.darkblue};
            }
            p{
                height:20px;
            }
        }

       
    }
`;