import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/homeBlog.js";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.js";

function HomeBlog({ data }) {
  const { t,i18n } = useTranslation();
  const history = useHistory();
  moment.locale(i18n?.language);

  return (
    <Styles>
      {/* Blog Area */}
      {data?.length > 0 && (
        <section className="home-blog-area">
          <Container>
            <Col
              md="12"
              className="d-flex justify-content-between align-items-start align-items-md-center flex-column flex-md-row p-2  "
            >
              <div>
                <h4 className="mb-3">{t("blog")}</h4>
               
              </div>
              <div>
                <div
                  className="blog-btn p-3 px-4 mt-3 mt-md-0  "
                  onClick={() => history.push({
                    pathname: "/blog-grid",
                    state: {
                      data:data,
                    },
                  })}
                >
                  {t("browseBlog")}
                </div>
              </div>
            </Col>
            <Col
              md="12"
              className={`blog-card-container mt-5 d-flex flex-md-row flex-xl-nowrap gap-5 gap-lg-4  py-2 ${
                data?.length === 4 ? "justify-content-lg-between" : "gap-5"
              }`}
            >
              {data?.slice(0, 4)?.map((item, index) => {
                return (
                  <div
                    className="blog-card  shadow rounded-3"
                    key={index}
                  >
                    <img
                      src={
                        item?.image
                      }
                      alt="blog-img"
                      loading="lazy"
                      width={'100%'}
                      height={'100%'}
                      className="rounded-3"
                      onClick={() => history.push({
                        pathname: "/blog-details",
                        state: {
                          id: item?._id,
                          data:data,
                        },
                      })
                    }
                    />
                    <p className="p1 p-1 pt-3 ">SCHOOL</p>
                    <p
                      className="p2 p-1 pb-2 "
                      onClick={() =>
                        history.push({
                          pathname: "/blog-details",
                          state: {
                            id: item?._id,
                            data:data,
                          },
                        })
                      }
                    >
                      {i18n?.language==='en'?item?.title_en:item?.title_fr}
                    </p>
                    <p className="p3 p-1 pb-2  ">
                      {moment(item?.created_at).format("MMMM DD,YY")}
                    </p>
                  </div>
                );
              })}
            </Col>
          </Container>
        </section>
      )}
    </Styles>
  );
}

export default HomeBlog;
