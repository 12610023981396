import * as React from 'react';
// import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';


// let t;
let useSnackbarRef;
export const SnackbarUtilsConfigurator = () => {
    useSnackbarRef = useSnackbar();
    // const translation = useTranslation();
    // t = translation.t;
    return null;
};

const SnackbarCloseButton = ({ id }) => {
    const { closeSnackbar } = useSnackbar();
    return (
        <button className='border-0 bg-transparent text-white-50 ' onClick={() => closeSnackbar(id)}>
            X
        </button>
    );
};
const notistack = {
    apiError(error) {
        let text = null;
        if (error.status === 400 && error.data.title) {
            for (const key in error.data.errors) {
                for (const keys in error.data.errors[key]) {
                    this.error(error.data.errors[key][keys]);
                }
            }
        } else if (error.status === 405) {
            text = 'error.405'
        } else if (error.status === 500) {
            text = 'error.500'
        } else if ([404, 403, 401].includes(error.status)) {
            text = error.data.message;
        } else if (error.status === 400) {
            text = error.data.message;
        } else if (error.status === 307) {
            this.info(error.data.message);
        }
        if (text) {
            this.error(text);
        }
    },
    success(msg) {
        this.toast(msg, 'success');
    },
    warning(msg) {
        this.toast(msg, 'warning');
    },
    info(msg) {
        this.toast(msg, 'info');
    },
    error(msg) {
        this.toast(msg, 'error');
    },
    toast(msg, variant = 'default') {
        useSnackbarRef.enqueueSnackbar(msg, {
            variant,
            preventDuplicate: true,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
            action: (key) => {
                return <SnackbarCloseButton id={key} />;
            }
        });
    },
};
export default notistack;