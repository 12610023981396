import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/courseSlider.js";
import {Icon} from '@iconify/react'
import { useTranslation } from 'react-i18next';

function CourseSlider()  {
    
    const {t}=useTranslation();

        return (
            <Styles>
                {/* Course Slider */}
                <section className="course-slider-area">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="sec-title text-center user-select-none">
                                    <h4>{t('stepsHire')}</h4>
                                    {/* <p>{t('stepsHirePara')}</p> */}
                                </div>
                            </Col>
                            <Col md="12" className='d-flex mt-5 flex-column flex-md-row'>
                            <div className="card-sec text-center col-md-4 col-12 align-items-center d-flex justify-content-center p-1 flex-column   ">
                                    <div className='icon-blk p-4 shadow  '>
                                    <Icon icon="uil:book-open" width={40} />
                                    </div>
                                    <h5 className='my-3 '>{t('simple')}</h5>
                                    <p>{t('coursePara')}</p>
                                </div>
                                <div className="card-sec text-center col-md-4 col-12 align-items-center d-flex justify-content-center p-1 flex-column   ">
                                    <div className='icon-blk p-4 shadow  '>
                                    <Icon icon="ph:graduation-cap" width={40} />
                                    </div>
                                    <h5 className='my-3 '>{t('header2')}</h5>
                                    <p>{t('coursePara2')}</p>
                                </div>
                                <div className="card-sec text-center col-md-4 col-12 align-items-center d-flex justify-content-center p-1 flex-column   ">
                                    <div className='icon-blk p-4 shadow  '>
                                    <img src={`${process.env.PUBLIC_URL}assets/images/subscriptionlogo.webp`} width={40} height={'100%'} alt='logo-subscription' />
                                    </div>
                                    <h5 className='my-3 '>{t('header3')}</h5>
                                    <p>{t('coursePara3')}</p>
                                </div>
                                
                               
                            </Col>
                           
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }


export default CourseSlider
