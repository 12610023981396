import React, { Component, lazy, Suspense, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { Styles } from "./styles/headerTwo.js";
import { Icon } from "@iconify/react";
import { colors } from "./common/element/elements.js";
import { useTranslation } from "react-i18next";
import { useDarkMode } from "@rbnd/react-dark-mode";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import auth from "../puglins/Utils/models/auth.js";
import { getAuthUser } from "../actions/initialData.js";
import noNetworkAction from "../puglins/Utils/errorManage.js";

const StickyMenu =lazy(()=>import('./common/StickyMenu'));
const MobileMenu =lazy(()=>import('./common/MobileMenu'))

function HeaderTwo() {
  const { mode, setMode } = useDarkMode();
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(language);
  const [token, setToken] = useState();
  const { authUserData } = useSelector(
    ({ intialDataReducer }) => intialDataReducer
  );
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    let token = localStorage.getItem("token");
    setToken(token);
  });

  // Handle langiage change
  const handleChangeLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "fr" : "en";
    setCurrentLanguage(newLanguage);
    changeLanguage(newLanguage);
    languageChangeMapping(newLanguage);
  };

  // Langauge change api mapping
  const languageChangeMapping = (lang) => {
    let sendData = {
      language: lang,
      id: authUserData?._id,
    };
    authUserData &&
      auth
        .setLanguage(sendData)
        .then((res) => dispatch(getAuthUser))
        .catch((error) => {
          error?.message === "Network Error" && noNetworkAction(history);
          console.log("Error while updating language", error);
        });
  };
  return (
    <Styles>
      {/* Topbar 2 */}
      {/* <section className="top-bar2 bg-danger-subtle ">
        <Container>
          <Row>
           
            <Col lg="5" md="3">
              <div className="bar-right d-flex justify-content-end">
                <ul className="list-unstyled list-inline ">

                  <div
                    className={`toggle-container me-2  ${
                      currentLanguage === "en" ? "on" : "off"
                    } `}
                    onClick={handleChangeLanguage}
                  >
                    <div className="toggle-background">
                      <div
                        className="position-absolute fw-bold fs-5  "
                        style={
                          currentLanguage === "en"
                            ? {
                                top: "5%",
                                left: "20%",
                                color: "#fff",
                              }
                            : {
                                top: "5%",
                                left: "55%",
                                color: "#fff",
                              }
                        }
                      >
                        {currentLanguage === "en" ? "EN" : "FR"}
                      </div>
                    </div>
                    <div className="toggle-button">
                      <img
                        src={
                          currentLanguage === "en"
                            ? process.env.PUBLIC_URL + "/assets/images/en.png"
                            : process.env.PUBLIC_URL + "/assets/images/fr.png"
                        }
                        alt={currentLanguage === "en" ? "On" : "Off"}
                        className="toggle-image"
                      />
                    </div>
                  </div>
                </ul>
                <ul className="list-unstyled list-inline d-flex align-items-center ms-2 ">
                 <div className=" d-flex justify-content-center align-items-center    " onClick={()=>setMode(mode==='dark'?'light':'dark')} style={{cursor:'pointer'}}>
                 {mode!=='dark'?<Icon icon="ph:moon-duotone" color="#000000" width={'25'} />:<Icon icon="ph:sun-duotone" color="yellow" width={'25'} />}
                 </div>
                </ul>

              </div>
            </Col>
          </Row>
        </Container>
      </section> */}

      {/* Logo Area 2 */}
      <section className="logo-area2   ">
        <Container>
          <Row>
            <Col md="3">
              <div className="logo">
                <Link
                  to={
                    token
                      ? authUserData?.first_time_online
                        ? "/firstlogin"
                        : "/dashboard"
                      : "/"
                  }
                >
                  <img
                    src={ process.env.PUBLIC_URL + "/assets/images/Logoheader.webp"}
                    alt="logo"
                    loading="lazy"
                    width="200px"
                    height="200px"
                  />
                </Link>
              </div>
            </Col>
            <Col md="9" className="d-flex justify-content-end ">
              <div className="menu-box d-flex align-items-center gap-2   ">
                {token ? (
                  <div className="apply-btn">
                    <Link
                      to={
                        authUserData?.first_time_online
                          ? "/firstlogin"
                          : "/dashboard"
                      }
                      className="px-4 py-2  "
                    >
                      {t("goToDashboard")}
                    </Link>
                  </div>
                ) : (
                  <>
                    <div className="apply-btn  ">
                      <Link
                        to={process.env.PUBLIC_URL + "/helpcenter"}
                        className="px-4 py-2  "
                      >
                        {t("help")}
                      </Link>
                    </div>
                    <div className="apply-btn  ">
                      <Link
                        to={process.env.PUBLIC_URL + "registrationMain/2"}
                        className="px-4 py-2  "
                      >
                        {t("signUp")}
                      </Link>
                    </div>
                    <div className="apply-btn  ">
                      <Link
                        to={process.env.PUBLIC_URL + "/becomeTeacher"}
                        className="px-4 py-2  "
                      >
                        {t("becomeTutor")}
                      </Link>
                    </div>
                    <div className="apply-btn  ">
                      <Link
                        to={process.env.PUBLIC_URL + "/login"}
                        className="px-4 py-2  "
                      >
                        {t("logIn")}
                      </Link>
                    </div>
                    {/* <div className="apply-md-btn">
                      <Link to={process.env.PUBLIC_URL + "/registrationMain/2"}>
                        <Icon icon="mdi:register" height={25} color={"#fff"} />
                      </Link>
                    </div> */}
                  </>
                )}
                <div
                  className=" d-flex justify-content-center align-items-center  "
                  onClick={() => handleChangeLanguage()}
                  style={{ cursor: "pointer" }}
                >
                  {currentLanguage === "en" ? (
                    <img
                      src={process.env.PUBLIC_URL + "/assets/images/en.webp"}
                      className="lang-img"
                      alt="langEn"
                      height={'100%'} width={'100%'}
                      loading="lazy"
                    />
                  ) : (
                    <img
                      src={process.env.PUBLIC_URL + "/assets/images/fr.webp"}
                      className="lang-img"
                      alt="langFr"
                      height={'100%'} width={'100%'}
                      loading="lazy"
                    />
                  )}
                </div>

                {/* Dark/Light Mode section */}
                <div
                  className="dark-btn d-flex justify-content-center align-items-center ms-2  "
                  onClick={() => setMode(mode === "dark" ? "light" : "dark")}
                  style={{ cursor: "pointer" }}
                >
                  {mode !== "dark" ? (
                    <Icon icon="ph:moon-duotone" color="#000000" width={"25"} />
                  ) : (
                    <Icon icon="ph:sun-duotone" color="yellow" width={"25"} />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Sticky Menu */}
     <Suspense fallback={<p className="d-none">...Loading</p>}> <StickyMenu /></Suspense>

      {/* Mobile Menu */}
      <Suspense fallback={<p className="d-none">...Loading</p>}><MobileMenu /></Suspense>
    </Styles>
  );
}

export default HeaderTwo;
