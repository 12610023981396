import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  .courses {
    margin-top: 30px;

    .sec-title {
      h4 {
        color: ${colors?.darkblue};
      }
    }
    .scroll-conatiner{
     @media (max-width: 1024px) {
          overflow-x: scroll;
          overflow-y:hidden;

        ::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
      }
   .card-box{
    height:180px;
    .sub-name{
        color:${colors?.darkblue};
        font-weight:500;
    }
    
   }
}
    button {
        border-radius: 20px;
        padding-left: 20px;
        background: ${colors?.darkblue};
        width: fit-content;
        padding: 12px;
        color: #fff;
        border: none;

        @media(max-width:992px){
          font-size:10px;
        }
      }
  }
`;
