import React, { useEffect, useRef, useState } from "react";
import { Styles } from "./styles/userList.js";
import Loader from "./common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "./common/element/elements.js";
import { getClassHistory } from "../actions/student.js";
import moment from "moment";
import ReactStars from "react-rating-stars-component";
import { Icon } from "@iconify/react";
import { teacher } from "../puglins/Utils/models/index.js";
import { getMyAdvert } from "../actions/teacher.js";
import {
  Link,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min.js";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import notistack from "../puglins/notistack.js";
import Alert from "./Alert.js";
import { useTranslation } from "react-i18next";
import { useDarkMode } from "@rbnd/react-dark-mode";
import "moment/locale/fr";
import LineChart from "../pages/Teacher/myAdvert/LineChart.js";
import noNetworkAction from "../puglins/Utils/errorManage.js";
import { getAuthUser } from "../actions/initialData.js";
import { Popover, Whisper } from "rsuite";

function DashboardOne() {
  const dispatch = useDispatch();
  const { authUserData } = useSelector(
    ({ intialDataReducer }) => intialDataReducer
  );
  const { classHistory } = useSelector(({ studentReducer }) => studentReducer);
  const { myAdvertData } = useSelector(({ teacherReducer }) => teacherReducer);
  const [advertData, setAdvertData] = useState([]);
  const [studentData, setStudentData] = useState();
  const [classHistoryData, setClassHistoryData] = useState([]);
  const [deleteID, setDeleteId] = useState();
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(true);
  const [widthState, setWidthState] = useState();
  const [paymentModel, setPaymentModel] = useState({
    classId: null,
    model: false,
  });
  const { t, i18n } = useTranslation();
  const { mode } = useDarkMode();
  const history = useHistory();
  moment.locale(i18n?.language);
  const [modal, setModal] = useState(0);
  const targetDivRef = useRef(null);
  const location = useLocation();
  const { state } = useLocation();

  useEffect(() => {
    studentData ? setTimeout(() => setLoader(false), 1000) : setLoader(true);
  }, [studentData]);

  useEffect(() => {
    authUserData && dispatch(getClassHistory(history, authUserData?._id));
    setStudentData(authUserData);
    authUserData?.userType === 1 && dispatch(getMyAdvert());
  }, [authUserData]);

  useEffect(() => {
    setClassHistoryData(classHistory);
    setAdvertData(myAdvertData);
  }, [classHistory, myAdvertData]);

  useEffect(() => {
    dispatch(getAuthUser(history));
    const updateWidthState = () => {
      setWidthState(window.innerWidth);
    };
    updateWidthState();
    window.addEventListener("resize", updateWidthState);

    return () => {
      window.removeEventListener("resize", updateWidthState);
    };
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (state === 1 && targetDivRef.current) {
        targetDivRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        targetDivRef.current.focus();
        history.replace({ ...location, state: null });
      }
    }, 2000); // Adjust the delay if necessary

    return () => clearTimeout(timeoutId);
  }, []);

  const speaker = (
    <Popover className="" style={{ background: colors?.orange }}>
      <style>
        {`
          
          .rs-popover-arrow::after {
            border-right-color: ${colors?.orange} !important; // Change the color to your desired color
          }
        `}
      </style>
      <p className="text-white fw-bold">{t("offlineMessage")}</p>
    </Popover>
  );
  const actionOnClassRequest = (data, status) => {
    if (status === 2 && classHistory.find((i) => i?.status === 2)) {
      notistack.warning(t("alreadyAcceptedWarning"));
    } else {
      let sendData = {
        id: data,
        status: status,
      };
      teacher
        .classRequestStatus(sendData)
        .then((res) => {
          dispatch(getClassHistory(history, authUserData?._id));
          if (res?.data?.status == 2) {
            setPaymentModel((prev) => ({
              ...prev,
              classId: res?.data,
              model: true,
              modelNo: 2,
            }));
          }
        })
        .catch((errr) => console.log("error", errr));
    }
  };

  // ---To delete the particular advert
  const deleteAdvert = () => {
    teacher
      .deleteAdvertByID(deleteID)
      .then(() => {
        dispatch(getMyAdvert(history));
        notistack.success("Advert Deleted Successfully");
        handleClose();
      })
      .catch((error) => {
        error?.message === "Network Error" && noNetworkAction(history);
        notistack.error("Error while deleting advert");
      });
  };

  //--- To upadte the Status of the advert Active or de-active
  const updateAdvertStatus = (id, index) => {
    let temp = [...advertData];
    temp[index]["advert"]["status"] = !advertData[index]?.advert?.status;
    setAdvertData([...temp]);
    teacher
      .updateAdvertStatus(id)
      .then((res) => notistack.success(t("advretPara")))
      .catch((error) => {
        error?.message === "Network Error" && noNetworkAction(history);
        notistack.error("Error while updating status");
      });
  };

  // Handle close for model
  const handleClose = () => {
    setShow(false);
    setModal(0);
  };

  // Handle open for model
  const handleShow = () => setShow(true);

  // Update status of the Teacher
  const updateStatus = () => {
    if (authUserData?.online !== 2) {
      teacher
        .updateStatus(authUserData?._id, authUserData?.online === 1 ? 0 : 1)
        .then(() => {
          notistack.success("Status updated");
          dispatch(getAuthUser(history));
        })
        .catch((error) => {
          error?.message === "Network Error" && noNetworkAction(history);
          notistack.error("Error while updating Status");
        });
    }
  };

  // Manage Waiting room Status

  const manageWaitingRoomStatus = () => {
    teacher
      .updateWaitingRoomStatus()
      .then(() => {
        dispatch(getAuthUser());
        notistack.success("Waiting Status Updated");
      })
      .catch(() =>
        notistack.error(
          "Error while change waiting room status. Try again later"
        )
      );
  };

  return (
    <Styles>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="page-content page-container mt-5 " id="page-content">
            <div className="">
              <div className="container">
                {authUserData?.userType === 1 && (
                  <div
                    className={`${
                      authUserData?.online
                        ? "status-blk-online"
                        : "status-blk-offline"
                    } status-blk col-12 d-flex justify-content-center align-items-center gap-3 my-3 fw-bold position-relative shadow  `}
                  >
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>{t("tooltipMsgDashborad")}</Tooltip>}
                    >
                      <Icon
                        icon="ph:info-duotone"
                        width={12}
                        className="position-absolute  "
                        style={{ right: "-8", top: "-8" }}
                      />
                    </OverlayTrigger>

                    {/* {authUserData?.online === 2 ? "In class" : "Status:"} */}
                    <Whisper
                      placement="rightEnd"
                      speaker={speaker}
                      open={!authUserData?.online ? true : false}
                    >
                      <Icon
                        icon="lets-icons:on-button-duotone"
                        width={35}
                        color={
                          authUserData?.online === 2
                            ? "yellow"
                            : !authUserData?.online
                            ? "black"
                            : "white"
                        }
                        onClick={() => updateStatus()}
                        style={{ cursor: "pointer" }}
                      />
                    </Whisper>
                  </div>
                )}
                <div className="row">
                  <div className="col-12 col-md-4">
                    <div className="col-12 d-flex justify-content-center">
                      <div className="img-block d-flex align-items-center justify-content-center ">
                        <img
                          src={
                            studentData?.image
                              ? studentData?.image
                              : process.env.PUBLIC_URL +
                                "/assets/images/profile.svg"
                          }
                        />
                      </div>
                    </div>
                    <div
                      className="col-12 fw-bold  d-flex justify-content-center my-2  "
                      style={{ fontSize: "24px" }}
                    >
                      <span>
                        {authUserData?.first_name?.charAt(0).toUpperCase() +
                          authUserData?.first_name?.slice(1)}{" "}
                        {authUserData?.last_name}
                      </span>
                    </div>
                    {authUserData?.userType == 1 && (
                      <>
                        <div className="d-flex justify-content-center align-items-center fw-bold fs-5    ">
                          <Icon
                            icon="tdesign:member"
                            height={25}
                            className="me-3 "
                            color={colors?.orange}
                          />
                          <span style={{ color: colors?.darkblue }}>
                            {authUserData?.membership} {t("member")}
                          </span>
                        </div>

                        {authUserData?.membership !== "Basic" && (
                          <>
                            <div
                              className="col-7 rounded m-auto mt-5 p-2 fs-4 fw-bold text-center text-white"
                              style={{
                                background: colors?.darkblue,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                history.push({
                                  pathname: "/ViewStatistics",
                                  state: "hello",
                                });
                              }}
                            >
                              {t("viewStatistics")}
                            </div>
                            <div className="col-12 col-md-10 rounded p-2 m-auto mt-5 shadow text-center shadow ">
                              <h6>{t("manageWaiting")}</h6>
                              <p className="my-1">{t("managePara")}</p>
                              <div className="form-switch d-flex justify-content-between  px-2 mt-2">
                                <p className="">
                                  {authUserData?.waiting_room_status
                                    ? "Active"
                                    : t("deactive")}{" "}
                                  :
                                </p>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  style={{ width: "30px" }}
                                  checked={authUserData?.waiting_room_status}
                                  onChange={(e) => manageWaitingRoomStatus()}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}

                    {/* -----Banner to upgrade the subscription plan--- */}
                    {authUserData?.userType == 1 &&
                      authUserData?.membership === "Basic" && (
                        <div className=" bg-primary-subtle rounded-4  p-2 m-4  ">
                          <div>
                            <p className="fs-5 text-black text-center  ">
                              {t("upgradePremium")}
                            </p>
                          </div>
                          <div className="d-flex px-5 mt-4 flex-column   ">
                            <div>
                              <ul className="text-black text-start">
                                <li>{t("upgradePara1")}</li>
                                <li>{t("upgradePara2")}</li>
                                <li>{t("upgradePara3")}</li>
                                <li>{t("upgradePara4")}</li>
                                <li>{t("upgradePara5")}</li>
                              </ul>
                            </div>
                            <div>
                              <Link to="/Subscription">
                                <button className="btn fw-bold text-white p-2 mt-4 ">
                                  {t("upgradeButton")}
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="col-12 col-md-8">
                    {/* ----------My Classes Block ------------------ */}
                    <div
                      className="list list-row block col-12 d-flex align-items-center flex-column border rounded mt-4 mt-md-0"
                      data-bs-theme={mode}
                    >
                      <div
                        className="fw-bold"
                        style={{ margin: 20, fontSize: 20 }}
                        tabIndex={-1}
                        ref={targetDivRef}
                      >
                        {t("myRecentClasses")}
                      </div>
                      {classHistory?.length > 0 ? (
                        classHistoryData
                          ?.filter((item) => {
                            return item?.status && item;
                          })
                          ?.slice(0, 5)
                          ?.map((item, index) => {
                            return (
                              <div
                                className="list-card list-item my-3 col-11 rounded   "
                                data-id="19"
                                key={index}
                                style={{
                                  borderLeft: `2px solid ${colors?.orange}`,
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  if (!e.target.closest("button")) {
                                    authUserData?.userType == 2
                                      ? history.push({
                                          pathname: "/Teacher-Details",
                                          state: {
                                            advertId: item?.advert_id?._id,
                                            teacherId:
                                              item?.advert_id?.user_id?._id,
                                          },
                                        })
                                      : history.push({
                                          pathname:
                                            process.env.PUBLIC_URL +
                                            "/myPublicProfile",
                                          state: {
                                            id: item?.student_id?._id,
                                            userType: 2,
                                          },
                                        });
                                  }
                                }}
                              >
                              
                                <div className="col-3 col-md-2  ">
                                  <div className="image-div  align-self-start">
                                    {item?.advert_id?.user_id?.image ||
                                    item?.student_id?.image ? (
                                      <img
                                        src={
                                          authUserData?.userType == 1
                                            ? item?.student_id?.image
                                            : item?.advert_id?.user_id?.image
                                        }
                                        alt="profile"
                                      />
                                    ) : (
                                      <span className="w-48 avatar gd-warning">
                                        {authUserData?.userType == 1
                                          ? item?.student_id?.first_name
                                              ?.slice(0, 1)
                                              .toUpperCase()
                                          : item?.advert_id?.user_id?.first_name
                                              .slice(0, 1)
                                              .toUpperCase()}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="w-100 ">
                                  <div className=" d-flex justify-content-between align-items-center   ">
                                    <div className=" col-3 col-md-4 col-lg-5  ">
                                      <span
                                        className="item-author fw-bold"
                                        data-abc="true"
                                      >
                                        {authUserData?.userType == 1
                                          ? `${item?.student_id?.first_name}`
                                          : `${item?.advert_id?.user_id?.first_name}`}
                                      </span>
                                      {authUserData?.userType == 2 && (
                                        <div className="item-except  text-sm h-1x">
                                          {i18n?.language === "en"
                                            ? item?.advert_id?.subject_expertise
                                                ?.subject_name
                                            : item?.advert_id?.subject_expertise
                                                ?.subject_name_fr}{" "}
                                          {t("teacher")}
                                        </div>
                                      )}
                                    </div>
                                    {authUserData?.userType === 1 &&
                                    item?.status === 4 ? (
                                      <div className="d-flex justify-content-around w-100 px-1">
                                        {widthState < 768 ? (
                                          <>
                                            <button
                                              className="btn req-btn fw-bold text-success d-flex align-items-center border border-1 border-success"
                                              onClick={() =>
                                                actionOnClassRequest(
                                                  item?._id,
                                                  2
                                                )
                                              }
                                            >
                                              <Icon
                                                icon="flat-color-icons:ok"
                                                className="me-0"
                                                fontSize={16}
                                              />
                                            </button>
                                            <button
                                              className="btn req-btn fw-bold text-danger d-flex align-items-center border border-1 border-danger"
                                              onClick={() =>
                                                actionOnClassRequest(
                                                  item?._id,
                                                  3
                                                )
                                              }
                                            >
                                              <Icon
                                                icon="ic:twotone-cancel"
                                                className="me-0"
                                                fontSize={16}
                                              />
                                            </button>
                                          </>
                                        ) : (
                                          <>
                                            <button
                                              className="btn req-btn fw-bold text-success d-flex align-items-center border border-2 border-success"
                                              onClick={() =>
                                                actionOnClassRequest(
                                                  item?._id,
                                                  2
                                                )
                                              }
                                            >
                                              <Icon
                                                icon="flat-color-icons:ok"
                                                className="me-2"
                                              />
                                              {t("accept")}
                                            </button>
                                            <button
                                              className="btn req-btn fw-bold text-danger d-flex align-items-center border border-2 border-danger"
                                              onClick={() =>
                                                actionOnClassRequest(
                                                  item?._id,
                                                  3
                                                )
                                              }
                                            >
                                              <Icon
                                                icon="ic:twotone-cancel"
                                                className="me-2"
                                              />
                                              {t("reject")}
                                            </button>
                                          </>
                                        )}
                                      </div>
                                    ) : (
                                      <div className=" col-6 col-md-6 col-lg-5 ">
                                        <div
                                          className="item-date d-md-flex align-items-center gap-2  "
                                          style={{ whiteSpace: "normal" }}
                                        >
                                          <span
                                            style={{
                                              color:
                                                item?.status == 1
                                                  ? "#3ac16a"
                                                  : item?.status == 2
                                                  ? "goldenrod"
                                                  : item?.status == 4
                                                  ? "blue"
                                                  : item?.status == 3 ||
                                                    item?.status == 5 ||
                                                    item?.status == 8
                                                  ? "red"
                                                  : item?.status == 6 ||
                                                    item?.status == 7
                                                  ? "orange"
                                                  : "grey",
                                            }}
                                          >
                                            {item?.status == 1
                                              ? t("completed") + ":"
                                              : item?.status == 2
                                              ? t("accepted")
                                              : item?.status == 3
                                              ? t("rejected")
                                              : item?.status == 4
                                              ? t("requested")
                                              : item?.status == 5
                                              ? t("requestFailed")
                                              : item?.status == 6
                                              ? t("onGoing")
                                              : item?.status == 7
                                              ? t("waitingRoom").slice(0, 1) +
                                                t("waitingRoom")
                                                  .slice(1)
                                                  .toLowerCase()
                                              : item?.status == 8
                                              ? authUserData?.userType === 1
                                                ? t("studentCancellation")
                                                : t("youExitWaiting")
                                              : t("pending")}
                                          </span>
                                          {item?.status == 1 &&
                                            (item?.teacher_review ||
                                            item?.student_review ? (
                                              <ReactStars
                                                count={5}
                                                size={18}
                                                activeColor="#ffd700"
                                                edit={false}
                                                value={
                                                  authUserData?.userType === 1
                                                    ? item?.student_review?.rating.toFixed()
                                                    : item?.teacher_review?.rating.toFixed()
                                                }
                                              />
                                            ) : (
                                              <span>
                                                {t("noFeedbackGiven")}
                                              </span>
                                            ))}
                                        </div>
                                        <div className="item-date  text-sm d-md-block ">
                                          {moment(item?.createdAt).calendar()}
                                          {/* days ago */}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  {item?.status === 4 && (
                                    <div className="border-top d-flex justify-content-between mt-2  px-2  ">
                                      <p>
                                        {t("classDuration")}:
                                        <span className="fw-bold ">
                                          {" "}
                                          {item?.duration >= 60
                                            ? item?.duration / 60
                                            : item?.duration}{" "}
                                          {item?.duration === 60
                                            ? "hr"
                                            : item?.duration > 60
                                            ? "hrs"
                                            : "min"}
                                        </span>
                                      </p>
                                      <p>
                                        {t("amountPaid")}:{" "}
                                        <span className="fw-bold ">
                                          {/* {Math.floor(item?.amount * 100) / 100} */}{" "}
                                          {authUserData?.userType === 1
                                            ? item?.amount.toFixed(2)
                                            : item?.additional_amount.toFixed(
                                                2
                                              )}
                                        </span>
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })
                      ) : (
                        <p className="py-5 fw-bold ">{t("noRecentClasses")}</p>
                      )}
                    </div>

                    {/* --------My advert block for teacher section -------- */}
                    {authUserData?.userType === 1 && (
                      <div className="list list-row block col-12 d-flex align-items-center flex-column border rounded  ">
                        <div
                          className="fw-bold"
                          style={{ margin: 20, fontSize: 20 }}
                        >
                          {t("myRecentAdverts")}
                        </div>
                        <div className=" col-11">
                          {advertData?.length > 0 ? (
                            advertData?.slice(0, 2)?.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="card-block me-2 shadow p-2 rounded d-flex justify-content-center justify-content-md-between mb-4  "
                                  style={{
                                    borderLeft: `2px solid ${colors?.orange}`,
                                  }}
                                >
                                  {" "}
                                  <div className="d-flex  align-items-center   col-8 ">
                                    <div className="profile-pic col-4 d-flex justify-content-center ">
                                      {item?.teacherDetails?.image ? (
                                        <img
                                          src={item?.teacherDetails?.image}
                                          alt=""
                                        />
                                      ) : (
                                        <span className="w-48 avatar gd-warning p-md-5 ">
                                          {item?.teacherDetails?.first_name
                                            .slice(0, 1)
                                            .toUpperCase()}
                                        </span>
                                      )}
                                    </div>
                                    <div className="ps-2 ">
                                      <h6 className="py-2 ">
                                        {item?.teacherDetails?.first_name}
                                      </h6>
                                      <div className="py-2 d-flex align-items-center  ">
                                        <Icon
                                          icon="material-symbols:star"
                                          width={20}
                                          color="goldenrod"
                                        />
                                        <span className="ms-2 ">
                                          <span className="fw-bold  ">
                                            {item?.feedback?.average_rating.toFixed()}
                                          </span>{" "}
                                          <Link
                                            to={{
                                              pathname:
                                                process.env.PUBLIC_URL +
                                                "/myPublicProfile",
                                              state: {
                                                id: authUserData?._id,
                                                userType:
                                                  authUserData?.userType,
                                              },
                                            }}
                                            className="text-none"
                                          >
                                            <span
                                              className="text-decoration-underline"
                                              style={{ cursor: "pointer" }}
                                            >
                                              ({item?.feedback?.total_reviews}{" "}
                                              {t("reviews")})
                                            </span>
                                          </Link>
                                        </span>
                                      </div>
                                      <div className="py-2 text-success d-flex align-items-center   ">
                                        <Icon
                                          icon="game-icons:upgrade"
                                          width={20}
                                          color="darkgreen"
                                        />
                                        <span className="ms-2 ">
                                          {"advert_level" in item?.advert
                                            ? item?.advert?.advert_level
                                            : "Basic"}
                                        </span>
                                      </div>
                                      {/* highLight div */}
                                      <div className="d-flex flex-column flex-md-row  ">
                                        <div
                                          className=" rounded-3 text-center fw-bold text-white px-3 me-md-2 py-2    "
                                          style={{
                                            backgroundColor: colors?.darkblue,
                                          }}
                                        >
                                          {i18n?.language==='fr'?item?.advert?.subject_name_fr:item?.advert?.subject_name}
                                        </div>
                                        <div
                                          className="rounded-3 text-center fw-bold px-3 py-2 mt-2 mt-md-0   "
                                          style={{ backgroundColor: "#89c6d4" }}
                                        >
                                          {item?.advert?.hourly_rate} €/hr
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* right card block  */}
                                  <div className="d-flex flex-column align-items-end">
                                    <div className="py-2 d-flex justify-content-center text-white fw-bold ms-3    ">
                                      <label className="switch">
                                        <input
                                          type="checkbox"
                                          id="toggleSwitch"
                                          checked={item?.advert?.status}
                                          onChange={() =>
                                            updateAdvertStatus(
                                              item?.advert?._id,
                                              index
                                            )
                                          }
                                        />
                                        <span
                                          className="slider py-1"
                                          style={
                                            !item?.advert?.status
                                              ? {
                                                  paddingLeft: 25,
                                                  backgroundColor: "red",
                                                  fontSize: "12px",
                                                  // paddingTop: "5px",
                                                }
                                              : {
                                                  paddingLeft: 10,
                                                  backgroundColor: "green",
                                                }
                                          }
                                        >
                                          {item?.advert?.status
                                            ? "Active"
                                            : t("deactive")}
                                        </span>
                                      </label>
                                    </div>
                                    {authUserData?.membership !== "Basic" && (
                                      <div className="py-2 d-flex align-items-center    ">
                                        <Icon
                                          icon="mdi:eye"
                                          width={15}
                                          className="me-md-2 "
                                          color={colors?.orange}
                                        />{" "}
                                        <span
                                          onClick={() => {
                                            setModal(2);
                                            setShow(
                                              item?.advert?.adverts_daily_views
                                            );
                                          }}
                                          style={{ cursor: "pointer" }}
                                          className="text-decoration-underline"
                                        >
                                          Views: {item?.advert?.total_views}
                                        </span>
                                      </div>
                                    )}
                                    <div className="text-primary float-end d-flex align-items-center   ">
                                      {/* <span>Modify Advert</span>  */}
                                      <Link
                                        to={{
                                          pathname: "/updatemyadvert",
                                          state: { id: item?.advert?._id },
                                        }}
                                      >
                                        <Icon
                                          icon="material-symbols:edit"
                                          width="30"
                                          color={colors?.darkblue}
                                        />
                                      </Link>
                                      <div
                                        className=" ms-3"
                                        onClick={() => {
                                          setModal(1);
                                          setDeleteId(item?.advert?._id);
                                          handleShow();
                                        }}
                                      >
                                        <Icon
                                          icon="material-symbols:delete"
                                          width="30"
                                          className="delete-icon"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <p className="py-5 fw-bold text-center  ">
                              {t("noRecentAdverts")}
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
          {paymentModel && (
            <Alert
              paymentModel={paymentModel}
              setPaymentModel={setPaymentModel}
            />
          )}
          {/*-----------------------------------------Model for Delete Advert----------------------------- */}

          {modal === 1 && (
            <Modal show={show} onHide={handleClose} style={{ zIndex: 9999 }}>
              <Modal.Header>
                <Modal.Title>{t("deleteAccount")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row my-2  fw-bold px-2 text-center   ">
                  <Icon icon="material-symbols:delete" width={35} color="red" />
                  <p className="mt-3">{t("deletePara")} ?</p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  {t("close")}
                </Button>
                <Button variant="danger" onClick={() => deleteAdvert()}>
                  {t("delete")}
                </Button>
              </Modal.Footer>
            </Modal>
          )}

          {/*-----------------------------------------Model for Statistics----------------------------- */}

          {modal === 2 && (
            <Modal show={show} onHide={handleClose} style={{ zIndex: 9999 }}>
              <Modal.Header>
                <Modal.Title>{t("viewAdvertStatistics")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <LineChart
                  viewData={show}
                  label={t("advertViews")}
                  bgColor="rgb(75, 192, 192)"
                  backColor="rgba(75, 192, 192,0.5)"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                  {t("close")}
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </>
      )}
    </Styles>
  );
}

export default DashboardOne;
