import React from "react";
import Chart from 'chart.js/auto';
import {  Line, } from "react-chartjs-2";
import moment from "moment";
import { useTranslation } from "react-i18next";


const LineChart = ({ viewData, bgColor,label,type,backColor }) => {
  console.log(viewData);
  const { i18n } = useTranslation();
  moment.locale(i18n?.language);
  const data = {
    labels: viewData?.map((i) => moment(i?.createdAt).format("DD/MM")),
    // labels: viewData?.map((i) =>i?.createdAt),
    datasets:[
      {
        label: label,
        data: type?viewData?.map((i) => i?.count):viewData?.map((i) => i?.total_views),
        fill: true,
        borderColor: bgColor,
        backgroundColor: backColor||'#fff',
        tension: 0.1,

      },
    ] }

    const options = {
      scales: {
        x: {
          grid: {
            display: false, // Remove grid lines on x-axis
          },
          ticks: {
            display: false, // Remove labels on x-axis
          },
          border: {
            display: false, // Remove axis line on x-axis
          },
        },
        y: {
          grid: {
            display: false, // Remove grid lines on y-axis
          },
          ticks: {
            display: false, // Remove labels on y-axis
          },
          border: {
            display: false, // Remove axis line on y-axis
          },
        },
      },
      plugins: {
        legend: {
          display: false, // Optionally remove legend
        },
      },
    };



  return (
    <div>
        <Line
          data={data}
          options={{
            scales: {
              y: {
                beginAtZero: true,
                grid: {
                  display: false, 
                },
                ticks: {
                  display: false, 
                },
                border: {
                  display: false, 
                },
              },
              x: {
                grid: {
                  display: false, 
                },
          
                border: {
                  display: false, 
                },
              },
            },
            
            responsive: true,
          }}
        />
     
    </div>
  );
};
export default LineChart;
