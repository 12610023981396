import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/heroImage.js";
import { colors } from "./common/element/elements.js";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom/cjs/react-router-dom.min.js";
import { useTranslation } from "react-i18next";

function HeroImage() {
  const { t } = useTranslation();
  return (
    <Styles>
      {/* Hero Image */}
      <section className="hero-image-area w-100 ">
        <Container className="mt-2 d-flex flex-column flex-md-row ">
          {/* ---------------left block */}
          <Col lg={7} className="d-flex flex-column ">
            <div className="mt-4 user-select-none">
              <h1>
                {t("heroPara")} <span>200+</span>
                <br /> {t("heroPar2")}.
                <br /> {t("heroPara3")}
              </h1>
            </div>

            {/* --------Get started button */}
            <div className="d-flex my-5 align-items-center user-select-none   ">
              <Link to={process.env.PUBLIC_URL + "/registrationMain/2"}>
                <div className="get-btn p-3  px-5 rounded-5 fs-5 text-white text-center  ">
                  {t("registration").slice(0,1)+t("registration").slice(1).toLowerCase()} 
                </div>
              </Link>
              <div className="video-icon d-flex align-items-center ms-3 fs-5   ">
                <Icon
                  icon="octicon:play-24"
                  width={49}
                  color={colors?.darkblue}
                  className="me-2 "
                />{" "}
                {t("watchVideo")}
              </div>
            </div>
            {/* --------Listing below ---------- */}
            <div className="d-flex gap-3  flex-wrap flex-column flex-lg-row align-items-baseline pointsec  user-select-none   ">
              <div className="list-below d-flex justify-content-center align-items-center ">
                <Icon
                  icon="fluent:hat-graduation-24-regular"
                  width={25}
                  className="me-1 "
                />
                {t("heroTag1")}
              </div>
              <div className="list-below d-flex justify-content-center align-items-center ">
                <Icon
                  icon="fluent:video-clip-20-regular"
                  width={25}
                  className="me-1 "
                />
                {t("heroTag2")}
              </div>
              <div className="list-below heroTag3 d-flex align-items-center">
                <Icon icon="gala:secure" width={25} className="me-1" />
                <div className="flex-grow me-2 d-flex align-items-center">
                  <div className="d-inline-block text-wrap mb-0">
                    {t("heroTag3")}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {/* -----------Right Block */}
          <Col
            lg={5}
            className="right-blk d-flex gap-2 align-items-center user-select-none  "
          >
            <div>
              <div className="position-relative ">
                <div className="grp-img3 rounded-3 shadow-sm p-3 ps-5   ">
                  <div className="badge p-2 ">
                    <Icon icon="cil:badge" width={25} />
                  </div>
                  <p className="mb-1 list-below fw-bold ">{t("congrats")}</p>
                  <p className="fw-bold   ">{t("conPara")}</p>
                </div>
               

                <img
                  className="img1"
                  src={`${process.env.PUBLIC_URL}/assets/images/Mask1.webp`}
                  loading="lazy"
                  height={'100%'} width={'100%'}
                  alt="MaskImg"
                />
              </div>
              <div className="position-relative mt-4    ">
                <div className="grp-img rounded-3 shadow-sm p-3 d-flex align-items-center gap-3     ">
                  <div className="small-pic  d-flex justify-content-center align-items-center ">
                  <Icon
                    icon="streamline:ecology-science-erlenmeyer-flask-experiment-lab-flask-science-chemistry-solution"
                    color={colors?.orange}
                    width={25}
                  />
                  </div>
                  <div>
                    <p className="mb-1 list-below fw-bold ">Mila C.</p>
                    <p className="fw-bold  ">{t('heroSub')}</p>
                  </div>
                </div>
               

                <img
                  className="img2"
                  src={`${process.env.PUBLIC_URL}/assets/images/Mask2.webp`}
                  loading="lazy"
                  height={'100%'} width={'100%'}
                  alt="mask2"
                />
              </div>
            </div>
            <div className="position-relative ">
              <div className="grp-img2 rounded-3 shadow-sm p-3 d-flex align-items-center gap-3     ">
                <div className="small-pic d-flex justify-content-center align-items-center  ">
                  <Icon
                    icon="ph:graduation-cap"
                    color={colors?.orange}
                    width={30}
                  />
                </div>
                <div>
                  <p className="mb-1 list-below fw-bold ">200+</p>
                  <p className="fw-bold   ">{t("teachersOnline")}</p>
                </div>
              </div>
             
              <img className="img3" src={`${process.env.PUBLIC_URL}/assets/images/Mask3.webp`} loading="lazy" alt="img3" height={'100%'} width={'100%'} />
            </div>
          </Col>
        </Container>
      </section>
    </Styles>
  );
}

export default HeroImage;
{
  /* <div className="video-player">
                                                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='uXFUl0KcIkA' onClose={() => this.setState({ isOpen: false })} />
                                                <button onClick={this.openModal} className="play-button"><i className="las la-play"></i></button>
                                            </div> */
}
