import loaderReducer from "./loaderReducer";
import intialDataReducer from './intialDataReducer'
import studentReducer from "./studentReducer";
import teacherReducer from "./teacherReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
    loaderReducer,
    intialDataReducer,
    studentReducer,
    teacherReducer
});
export default rootReducer;