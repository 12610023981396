import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  .testimonial-area {
    padding-top: 20px;
    margin-top: 40px;
    clip-path: polygon(0 0, 100% 0, 100% 75%, 0 100%);
    height: 620px;
    background-repeat: no-repeat;
    background-size: cover;
    background:rgba(179, 224, 239,0.4);

    @media (max-width: 993px) {
      background-size: cover;
      height: fit-content;
    }
    .sec-title {
      h4 {
        color: ${colors?.darkblue};
      }
    }
    .main-contents {
      @media (max-width: 993px) {
        flex-direction: column;
      }
      .right-sec {
        @media (max-width: 993px) {
          margin-top: 100px;
          padding-bottom: 200px !important ;

        }
        h4 {
          color: ${colors?.darkblue};
          font-size: 20px;
        }
        div {
          span {
            color: ${colors?.darkblue};
            font-weight: 500;
            font-size: 14px;
          }
        }
      }
      .left-sec {
        .test-card {
          width: 268px;
          height: 315px;
          padding: 20px;
          background:#fff;

          .test-card-inner {
            top: 50px;
            right: -170px;
            width: 233px;
            height: 350px;
            background:#fff;

            @media (max-width: 450px) {
                right: -70px;
                width: 200px;
              }

            img {
              height: 255px;
              width: 233px;
              @media (max-width: 450px) {
              
                width: 200px;
              }
            }
            .down-blk {
              padding: 20px;

              p {
                font-size: 12px;
                color: ${colors?.darkblue};
              }
              span {
                font-size: 10px;
              }
            }
          }

          p {
            color: ${colors?.darkblue};
            font-weight: 500;
            font-size: 12px;

            span {
              color: ${colors?.orange};
            }
          }

          .list-blk {
            img {
              width: 45px;
              height: 45px;
              border-radius: 50%;
            }

            .details-testo {
              p {
                color: ${colors?.darkblue};
                font-weight: 700;
                font-size: 12px;
              }
              font-size: 10px;
            }
          }
        }
      }
    }
  }
`;
