const intialState = {
  favData: null,
  classHistory: null,
  takeclassdata:null,
};

const studentReducer = (state = intialState, action) => {
  switch (action?.type) {
    case "GETFAV_SUCCESS":
      return {
        ...state,
        favData: action.payload,
      };
    case "GETFAV_FAILURE":
      return {
        ...state,
        favData: action.payload,
      };
    case "CLASSHISTORY_SUCCESS":
      return {
        ...state,
        classHistory: [...action?.payload],
      };
    case "CLASSHISTORY_FAILURE":
      return {
        ...state,
        classHistory: action?.payload,
      };

    default:
      return state;
  }
};

export default studentReducer;
