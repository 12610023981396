import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  .service-area {
    padding: 56px 0 42px;
    background: ${colors.darkblue};

    .sec-title {
      height: 80px;
      margin-bottom: 15px;

      h4 {
        color: #fff;
        font-weight: 600;
        @media (max-width: 575px) {
          margin-bottom: 15px;
          font-size: 20px;
        }
      }
    }

    .service-box {
      box-shadow: 0 12px 25px rgba(0, 0, 0, 0.07);
      border-radius: 5px;
      margin-bottom: 10px;
      background: #fff;
      height: 120px;

      .box-icon {
        width: 80px;
        height: 80px;
        border-radius: 100%;
        background: #feebd6;
        @media (max-width: 991px) {
          width: 60px;
          height: 60px;
        }
        svg {
          color: ${colors?.orange};
        }
      }

      .box-title {
        h6 {
          color: ${colors.orange};
          // text-transform: uppercase;
          font-weight: 600;
          margin-bottom: 6px;

          @media (max-width: 991px) {
            font-size: 12px;
            letter-spacing: 0;
          }

          @media (max-width: 767px) {
            font-size: 16px;
            letter-spacing: 0.3px;
          }

          @media (max-width: 575px) {
            font-size: 15px;
          }
        }

        p {
          font-size: 14px;
          font-weight: 500;


          @media (max-width: 1200px) {
            font-size: 12px;
            letter-spacing: 0;
          }
          @media (max-width: 991px) {
            font-size: 9px;
            letter-spacing: 0;
          }

          @media (max-width: 767px) {
            font-size: 14px;
            letter-spacing: 0.3px;
          }
        }
      }

    }

    @media (max-width: 767px) {
      padding: 30px 0 15px;
    }
  }
  .private-tutor {
    background: ${colors?.darkblue};
    .find-blk {
      // height: 200px;
      h4 {
        color: #fff;
      }
      button {
        border-radius: 20px;
        padding-left: 20px;
        background: ${colors?.orange};
        width: 135px;
        padding: 12px;
        font-weight: 500;
        color: #fff;
        border: none;
      }
    }
  }
`;
