import noNetworkAction from "../puglins/Utils/errorManage";
import { student } from "../puglins/Utils/models";


export const getFavorite = (history) => {
  return (dispatch) => {
    student
      .getFavourite()
      .then((res) => dispatch({ type: "GETFAV_SUCCESS", payload: res }))
      .catch((error) => {
        error?.message==='Network Error'&&noNetworkAction(history);
        dispatch({ type: "GETFAV_FAILURE", payload: error })});
  };
};

export const getClassHistory = (history,id) => {
  return (dispatch) => {
    student
      .getClassHistory(id)
      .then((res) => {
        dispatch({ type: "CLASSHISTORY_SUCCESS", payload: res?.class_history ||[]
      });
      })
      .catch((error) => {
        error?.message==='Network Error'&&noNetworkAction(history)
        dispatch({ type: "CLASSHISTORY_FAILURE", payload: [error] });
        console.log("Error while getting data");
      });
  };
};


