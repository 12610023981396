import styled from 'styled-components';

const bgImageUrl = `${process.env.PUBLIC_URL}/assets/images/Vector.webp`;

export const ImgWrap = styled.div`
  background-size: contain;
  height: 550px;
  background-repeat: no-repeat;
  background-image: url(${bgImageUrl});

  @media (max-width: 768px) {
background-image: linear-gradient(50deg, #a1d9eb 40%, #a1d9eb 60%, #a0dbab 100%);
    height: auto;
  }
  @media (max-width: 1000px) {
    background-size: cover;
    height: auto;
  }
  @media (min-width: 1300px) {
    background-size: cover;
    height: 550px;
  }
  @media (min-width: 1400px) {
    height: 600px;
  }
  @media (min-width: 1500px) {
    height: 650px;
  }
  @media (min-width: 1600px) {
    height: 670px;
  }
`;
