import React, { Component, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/testimonialSlider.js";
import { colors } from "./common/element/elements.js";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import subjectGrade from "../puglins/Utils/models/subjectsGrade.js";
import noNetworkAction from "../puglins/Utils/errorManage.js";
import {useHistory } from "react-router-dom";


function TestimonialSlider() {
  const { t,i18n: { language } } = useTranslation();
  const [data, setData] = useState([]);
  const history=useHistory();
  useEffect(() => {
    getTestimonial();
  }, []);

  const getTestimonial = () => {
    subjectGrade
      .getTestimonial()
      .then((res) => setData(res?.data))
      .catch((error) =>{ error?.message==='Network Error'&&noNetworkAction(history); console.log("Error while getting the Testimonial data")});
  };

  console.log("data", data);
  return (
    <Styles>
      {/* Testimonial Slider */}
      {data?.length>0&&<section
        className="testimonial-area"
        style={
          {
            // backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/Vector2.jpg)`,
          }
        }
      >
        <Container>
          <Col md="12">
            <div className="sec-title text-center  mt-5  ">
              <h4 className="mb-4 ">{t("testimoniHeader")}</h4>
              {/* <p className="">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit
              </p> */}
            </div>
          </Col>
          <Col
            md="12"
            className="main-contents d-flex justify-content-center align-items-center "
          >
            <div className="left-sec col-md-6 col-12 ">
              <div className="test-card   rounded-3 position-relative shadow-sm   ">
                {/* *************Inter abosolute card with single image  */}
                <div className="test-card-inner  rounded-3 position-absolute shadow-sm  ">
                  <img
                    src={data[0]?.teacher_image}
                    className="rounded-top-3 "
                  />
                  <div className="down-blk">
                    <p className="fw-bold ">{data[0]?.teacher_name}</p>
                    <span className="  ">{language==='en'?data[0]?.subject:data[0]?.subject_name_fr}</span>
                    <div className="d-flex ">
                      <div className="d-flex align-items-center text-warning me-3   ">
                        <Icon
                          icon="mingcute:star-fill"
                          className="me-1 "
                          width={15}
                        />{" "}
                        {data[0]?.rating.toFixed()}
                      </div>
                      {/* <div className="icon-blk d-flex align-items-center text-black-50  ">
                        <Icon icon="uil:book-open" className="" width={15} /> 5
                        {t("courses")}
                      </div> */}
                    </div>
                  </div>
                </div>
                {/* *************Inter abosolute card with single image End block  */}
                <p>
                  <span>5000+</span> {t("verifiedTeachers")}
                </p>

                {data?.map(item=>{return(<div className="list-blk d-flex mt-3 align-items-center  ">
                  <div>
                    <img
                      src={item?.teacher_image}
                    />
                  </div>
                  <div className="details-testo ms-3 ">
                    <p>{item?.teacher_name}</p>
                    {language==='en'?item?.subject:item?.subject_name_fr}
                  </div>
                </div>)})}
              
              </div>
            </div>
            <div className="col-md-6 col-12  right-sec pb-5 ">
              <h4>“{data[0]?.feedback}”</h4>
              <div className="d-flex align-items-center mt-5  text-black    ">
                <Icon
                  icon="fa6-regular:user"
                  width={20}
                  color={colors?.darkblue}
                />{" "}
                <span className="mx-2  ">{data[0]?.student_name}</span> ({data[0]?.subject} {t("learnerOn")}
                “Acadelic”)
              </div>
            </div>
          </Col>
        </Container>
      </section>}
    </Styles>
  );
}

export default TestimonialSlider;
