import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Timer = ({ onTimerComplete }) => {
  const [seconds, setSeconds] = useState(120);
  const {t,i18n}=useTranslation();
  
  useEffect(() => {
      const intervalId = setInterval(() => {
          setSeconds((prevSeconds) => {
              // Stop the countdown if it reaches zero
              if (prevSeconds === 0) {
                  clearInterval(intervalId);
                  onTimerComplete(false);
                  return 0;
                }
        return prevSeconds - 1;
      });
    }, 1000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [onTimerComplete]);

  const formatTime = () => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  return (
   
    <p className="container fw-bold ">
    {i18n?.language === 'fr' ? (
      <>
       
        <span className="text-primary"> {t('resendNote')}</span> {formatTime()} <span className="text-primary"> {t('mins')}</span>
      </>
    ) : (
      <>
        {formatTime()}
        <span className="text-primary"> {t('resendNote')}.</span>
      </>
    )}
  </p>
  
  
  );
};

export const AcceptTimer=({onTimerComplete})=>{
  const [seconds, setSeconds] = useState(180);
  
  useEffect(() => {
      const intervalId = setInterval(() => {
          setSeconds((prevSeconds) => {
              // Stop the countdown if it reaches zero
              if (prevSeconds === 0) {
                  clearInterval(intervalId);
                  // onTimerComplete(false);
                  return 0;
                }
        return prevSeconds - 1;
      });
    }, 1000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [onTimerComplete]);

  const formatTime = () => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return ` ${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")} `;
  };

  return (
   
      <p className=" fw-bold text-danger "> {formatTime()} </p>
  
  );
}

export default Timer;
