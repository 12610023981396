import axios from "../axios";
import auth from "./auth";

const cms = {
  getLegalNotice() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get("page/1")
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
         err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  getFaq() {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get("page/2")
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
         err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },
  getCMSData(id) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`page/${id}`)
        .then(({ data }) => resolve(data))
        .catch((err) => {
          reject(err);
         err?.response?.status === 401 && auth?.destroyToken();
        });
    });
  },


};

export default cms;
