import styled from "styled-components";
import { colors } from "../element/elements";

export const Styles=styled.div`
.loader-main{
    display:flex;
    justify-content:center;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 99;
    
.loader {
    width: 160px;
    height: 140px;
    background: #136b7f96;
    box-sizing: border-box;
    position: absolute;
    border-radius:8px;
    perspective: 1000px;
    top:40%;
  }

  .loader:before{
    content: '';
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    bottom: 10px;
    border-radius:8px;
    background: ${colors?.darkblue}  no-repeat;
    background-size: 60px 10px;
    background-image: 	linear-gradient(#fff 100px, transparent 0) ,
              linear-gradient(#fff 100px, transparent 0), 
              linear-gradient(#fff 100px, transparent 0), 
              linear-gradient(#fff 100px, transparent 0), 
              linear-gradient(#fff 100px, transparent 0), 
              linear-gradient(#fff 100px, transparent 0);
    
    background-position: 10px 30px , 10px 60px , 10px 90px, 
              75px 30px , 75px 60px , 75px 90px;
    box-shadow: 0 0 10px rgba(0,0,0,0.25);
  }
  .loader:after {
    content: '';
      position: absolute;
      width: calc(50% - 10px);
      right: 10px;
      top: 10px;
      bottom: 10px;
      border-radius: 8px;
      background: #fff no-repeat;
      background-size: 60px 10px;
      background-image: linear-gradient(${colors?.darkblue} 100px, transparent 0), 
              linear-gradient(${colors?.darkblue} 100px, transparent 0), 
              linear-gradient(${colors?.darkblue} 100px, transparent 0);
      background-position: 50% 30px ,50% 60px , 50%  90px;
      transform: rotateY(0deg );
      transform-origin: left center;
    animation: paging 1s linear infinite;
  }


  @keyframes paging {
    to {
      transform: rotateY( -180deg );
    }
  }
}
`