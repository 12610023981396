import React from "react";
import HeaderTwo from "./components/HeaderTwo";
import HeroImage from "./components/HeroImage";
import ServiceBox from "./components/ServiceBox";
import CourseSlider from "./components/CourseSlider";

import TestimonialSlider from "./components/TestimonialSlider";
import HomeBlog from "./components/HomeBlog";
import FooterTwo from "./components/FooterTwo";
import Courses from "./components/Courses";
import ExploreCourse from "./components/ExploreCourse";
import { subjectGrade } from "./puglins/Utils/models";
import { useEffect } from "react";
import { useState } from "react";
import { ImgWrap } from "./components/styles/HomeTwo";


export default function HomeTwo() {

  const[popAdverts,setPopAdverts]=useState([]);
  const[blog,setBlog]=useState([]);
  const[category,setCategory]=useState([]);

  useEffect(()=>{
    getPopularAdverts();
    getBlog();
    getAllCategoryAdverts();
  },[])
  const getPopularAdverts = () => {
    subjectGrade
      .getPopularAdverts()
      .then((res) =>setPopAdverts(res?.data))
      .catch((err) => console.log("Error while getting popular adverts"));
  };
  const getBlog = () => {
    subjectGrade
      .getBlogs()
      .then((res) =>setBlog(res?.data))
      .catch((err) => console.log("Error while getting popular adverts"));
  };
  const getAllCategoryAdverts = () => {
    subjectGrade
      .getAllAdverts()
      .then((res) =>setCategory(res?.data?.adverts))
      .catch((err) => console.log("Error while getting popular adverts"));
  };

  return (
    <div className="main-wrapper">
      {/* Header 2 */}
      <ImgWrap
        className="img-wrap"
   
      
      >
        <HeaderTwo />

        {/* Hero Image */}
        <HeroImage />
      </ImgWrap>

      {/* Steps to Hire */}
      <CourseSlider />
      {/* Explore Course*/}
      <ExploreCourse subject={popAdverts} setCategory={setCategory} category={category} getAllCategoryAdverts={getAllCategoryAdverts} />
      {/* Testimonial Slider */}
      {/* <TestimonialSlider /> */}
      {/* How it Works */}
      {/* <HowWorks /> */}
      {/* Avantages d'Acadeclic */}
      <ServiceBox />


      {/* Popular adverts  */}
      <Courses data={popAdverts} />


      {/* Team Slider */}
      {/* <TeamSlider /> */}


      {/* Blog Area */}
      <HomeBlog data={blog} />

      {/* Image Gallery Area */}
      {/* <ImageGallery /> */}

      {/* Footer 2 */}
      <FooterTwo />
    </div>
  );
}
