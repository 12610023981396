const initialState = {
  advertData: null,
  personalInfo: null,
  myAdvertData: null,
  degreeData: null,
  universitData:null,
  areaOfInterestData:null,
  onClassDetails:null,
};

const teacherReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADVERT_DATA":
      return {
        ...state,
        advertData: action.payload,
      };
    case "PERSONAL_INFO":
      return {
        ...state,
        personalInfo: action.payload,
      };
    case "MYADVERTDATA":
      return {
        ...state,
        myAdvertData: action?.payload,
      };
    case "MYADVERTDATA_ERROR":
      return {
        ...state,
        myAdvertData: action?.payload,
      };
    case "GET_DEGREE":
      return {
        ...state,
        degreeData: action?.payload,
      };
    case "GET_DEGREE_ERROR":
      return {
        ...state,
        degreeData: action?.payload,
      };
    case "GET_UNIVERSITY":
      return {
        ...state,
        universitData: action?.payload,
      };
    case "GET_UNIVERSITY_ERROR":
      return {
        ...state,
        universitData: action?.payload,
      };
    case "GET_AOI":
      return {
        ...state,
        areaOfInterestData: action?.payload,
      };
    case "GET_AOI_ERROR":
      return {
        ...state,
        areaOfInterestData: action?.payload,
      };
    case "ONGOING_CLASS":
      return {
        ...state,
        onClassDetails: action?.payload,
      };

    default:
      return state;
  }
};

export default teacherReducer;
