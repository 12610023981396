import styled from "styled-components";
import { colors } from "../common/element/elements.js";
export const Styles = styled.div`
    .top-bar2 {
        height       : 40px;
        border-bottom: 1px solid ${colors.border1};

        .bar-left {
            margin-top: 8px;
           

            ul {
               
                li {
                    font-size   : 14px;
                    color       : ${colors.text2};
                    margin-right: 20px;
                    display     : inline-block;

                    i {
                        font-size     : 18px;
                        color         : ${colors.orange};
                        vertical-align: text-bottom;
                        margin-right  : 5px;

                        @media(max-width: 1199px) {
                            margin-right : 2px;
                        }
                    }

                    @media(max-width: 1199px) {
                        margin-right : 8px;
                    }

                    @media(max-width: 991px) {
                        font-size : 13px;
                    }
                }
            }
        }

        .bar-right {
            margin-top: 4px;

            ul.bar-lang {
                margin-right: 30px;
                position    : relative;
                margin-top  : 3px;

                &::before {
                    position  : absolute;
                    content   : "";
                    background: ${colors.border1};
                    width     : 1px;
                    height    : 20px;
                    top       : 0;
                    right     : -15px;
                }

                li {
                    .dropdown {
                        button.dropdown-toggle {
                            font-size : 13px;
                            color     : ${colors.text2};
                            background: transparent;
                            border    : none;
                            padding   : 0;
                            box-shadow: none;

                            img {
                                margin-right: 5px;
                                max-width   : 21px;
                            }

                            i {
                                font-size  : 12px;
                                margin-left: 3px;
                            }

                            &::after {
                                display: none;
                            }

                            &:hover {
                                cursor: pointer;
                                color : ${colors.orange};
                            }
                        }

                        ul.dropdown-menu {
                            padding         : 0;
                            margin          : 0;
                            border          : none;
                            background-color: #ffffff;
                            border-radius : 5px;
                            top       : 50% !important;
                            left      : -16px !important;
                            box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);

                            li {
                                display      : block;
                                border-bottom: 1px solid ${colors.border1};
                                padding      : 7px 15px;
                                color        : ${colors.text3};
                                cursor       : pointer;

                                img {
                                    max-width   : 21px;
                                    margin-right: 5px;
                                }

                                &:hover {
                                    color           : ${colors.black1};
                                    font-weight     : 500;
                                    background-color: transparent;
                                }

                                &:last-child {
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                }
            }

            

            ul.sidebar-button {
                li.side-box {
                    a.nav-link {
                        font-size  : 20px;
                        padding    : 0;
                        line-height: 28px;
                        i {
                            color : ${colors.orange};
                        }
                    }
                }
            }
        }
        
        @media(max-width: 767px) {
            display: none;
        }
    }

    .logo-area2 {
        // height     : 82px;
        padding: 20px;
        // background-image: url(${process.env.PUBLIC_URL}/assets/images/Vector.png); 
        background:rgba('0,255,255,0');
       display:flex;
       align-items:center;
       


        .logo {
            a {
                img {
                    height:35px;
                    object-fit:contain;
                    @media(max-width: 991px) {
                        max-width: 100%;
                        height:30px;
                    }
                }
            }
        }

        div.menu-box {
            ul.nav.menu-nav {
                li.nav-item {
                    position: relative;

                    a.nav-link {
                        font-size     : 14px;
                        color         : ${colors.black1};
                        text-transform: uppercase;
                        font-weight   : 500;
                        padding       : 10px 10px 20px;

                        i {
                            font-size: 12px;
                        }

                        &:after {
                            content: none;
                        }

                        &:hover {
                            color: ${colors.orange};
                        }

                        @media(max-width: 1199px) {
                            padding: 10px 2px 20px;
                            letter-spacing: 0;
                        }
                    }

                    ul.dropdown {
                        position  : absolute;
                        left      : 0;
                        top       : 100%;
                        min-width : 190px;
                        background: #fff;
                        border    : 1px solid ${colors.border1};
                        text-align: left;
                        padding   : 0;
                        border-radius : 5px;
                        transition : 0.2s ease;
                        opacity         : 0;
                        transform       : scaleY(0);
                        visibility      : hidden;
                        z-index         : 999;
                        transform-origin: center top 0;

                        li.nav-item {
                            position: relative;

                            a.nav-link {
                                font-size     : 13px;
                                color         : ${colors.text1};
                                padding       : 10px 20px;
                                text-transform: capitalize;
                                font-weight   : 400;
                                margin-right  : 0;
                                border-bottom : 1px solid ${colors.border1};

                                &:hover {
                                    color: ${colors.orange};

                                    i {
                                        color: #fff;
                                    }
                                }

                                i {
                                    float     : right;
                                    margin-top: 4px;
                                }
                            }

                            &:last-child {
                                margin-left: 0;

                                a.nav-link {
                                    border-bottom: none;
                                }
                            }

                            ul.dropdown2 {
                                position  : absolute;
                                top       : 0;
                                left      : 100%;
                                min-width : 180px;
                                background: #fff;
                                border    : 1px solid ${colors.border1};
                                transition : 0.2s ease;
                                opacity         : 0;
                                transform       : scaleY(0);
                                visibility      : hidden;
                                z-index         : 999;
                                transform-origin: center top 0;
                            }

                            &:hover {
                                ul.dropdown2 {
                                    opacity   : 1;
                                    transform : scaleY(1);
                                    visibility: visible;
                                }
                            }
                        }
                    }

                    &:hover {
                        ul.dropdown {
                            opacity   : 1;
                            transform : scaleY(1);
                            visibility: visible;
                        }
                    }
                }
            }


            .apply-btn {
               
                a {
                    font-size     : 13px;
                    color         : ${colors?.darkblue};
                    display       : inline-block;
                    width         : fit-content;
                    height        : 35px;
                    text-align    : center;
                    text-transform: uppercase;
                    font-weight   : 500;
                    border-radius : 20px;
                    border:1px solid ${colors?.darkblue};
                    position: relative;
                    transition: all 0.3s ease;

                    &:hover{
                        border:1px solid ${colors?.orange};
                        color         : ${colors?.orange};

                    }
                 @media(max-width: 991px ) {
                 font-size     : 10px;
                }

                }

               
            }
            // .apply-md-btn{
            //     display : none;
            //     @media(max-width: 991px) {
            //         display: flex;
            //         padding: 5px;
            //         background: rgb(19, 107, 127);
            //         border-radius: 100%;
            //         width: 40px;
            //         height: 40px;
            //         justify-content: center;
            //         align-items: center;
            //         margin-left: auto;
            //     }
            // }
        }

        @media(max-width: 767px) {
            display: none;
        }
    }
    .toggle-container {
        position: relative;
        width: 70px;
        height: 30px;
        cursor: pointer;
      }
      
      .toggle-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${colors?.darkblue};
        border-radius: 15px;
        transition: background-color 0.3s ease;
        box-shadow: inset 0px 0px 2px 2px rgb(137 128 128);
      }
      
      .toggle-button {
        position: absolute;
        top: 10%;
        left: 4%;
        width: 40%;
        height: 80%;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        transition: transform 0.3s ease;
        overflow:hidden;
      }
      
      .toggle-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 30px;
        overflow:hidden;
       
      }
      
      /* Styles for the 'on' state */
      .toggle-container.on .toggle-background {
        background-color: ${colors?.orange};
      }
      
      .toggle-container.on .toggle-button {
        transform: translateX(130%);
      }
      .lang-img{
        width:25px;
        height:25px;
        border-radius:50%;
        object-fit:contain;
      }
    
      
`;