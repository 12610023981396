import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
 
.status-blk{
  width:50px;
  height:50px;
  border-radius:50%;
}
.status-blk-online{
  background:${colors?.green};

}
.status-blk-offline{
  background:${colors?.orange};

}

  .list-card {
    
    box-shadow: 0px 0px 5px #dadada;
    &:hover {
      box-shadow: 2px 2px 10px #dadada;
    }
  }

  .block,
  .card {
    // background: #fff;
    border-width: 0;
    border-radius: 0.25rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    margin-bottom: 1.5rem;
  }

  .avatar {
    position: relative;
    line-height: 1;
    border-radius: 500px;
    white-space: nowrap;
    font-weight: 700;
    border-radius: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border-radius: 500px;
    box-shadow: 0 5px 10px 0 rgba(50, 50, 50, 0.15);
  }

  .avatar img {
    border-radius: inherit;
    width: 100%;
  }

  .gd-warning {
    color: #fff;
    border: none;
    background: #f4c414 linear-gradient(45deg, #f4c414, #f45414);
  }

  @media (min-width: 992px) {
    .page-container {
      max-width: 1140px;
      margin: 0 auto;
    }
    .page-sidenav {
      display: block !important;
    }
  }

  .list {
    padding-left: 0;
    padding-right: 0;
  }

  .list-item {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
  }

  .list-row .list-item {
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.75rem 0.625rem;
  }

  .list-row .list-item > * {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .text-gd {
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    text-fill-color: transparent;
  }

  .text-sm {
    font-size: 0.825rem;
  }

  .h-1x {
    height: 1.25rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .w-48 {
    width: 48px !important;
    height: 48px !important;
  }

  a:link {
    text-decoration: none;
  }
  .image-div {
    border-radius: 50%;
    width: fit-content;
    overflow: hidden;
    border: 2px solid ${colors?.darkblue};
    img{
      width: 50px;
      height: 50px ;
      object-fit: contain;

    }
  }

  button {
    background-color: ${colors?.darkblue};
    &:hover {
      background-color: ${colors?.darkbluehover};
    }
  }

  .req-btn {
    background-color: #fff;
    scale: 1;
    transition: all 0.3 ease-in;
    &:hover {
      scale: 1.1;
      background-color: #fff !important;
      box-shadow: 5px 5px 5px #dadada;
    }
  }

  .card-block {
    .profile-pic {
      img {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        object-fit: contain;

        @media (max-width: 767px) {
          width: 80px;
          height: 80px;
        }
      }
    }
    .switch {
      position: relative;
      display: inline-block;
      width: 80px;
      height: 24px;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      border-radius: 34px;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      border-radius: 50%;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #2196f3;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
      transform: translateX(55px);
    }
  }
  .delete-icon {
    cursor: pointer;
    color: red;
    &:hover {
      color: darkred;
    }
  }
  .img-block{
    border-radius: 25px;
    background: #dadada;
    height: 150px;
    width: 250px;
    overflow:hidden;
    img{
      border-radius: 25px;
      height: 150px;
      width: 250px;
      object-fit:contain;
    }
  }
  ul{
    text-align: justify;

  }
    .fade modal show{
    z-index:99999;
    }
  
  
  
`;
