import React from "react";
import FooterTwo from "../../components/FooterTwo";
import { Styles } from "./styles/dashboard.js";
import DashboardOne from "../../components/DashboardOne";
import DashboardNavbar from "../../components/DashboardNavbar";

function Dashboard() {
  
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper about-page">
          {/* Header 2 */}
          {/* <HeaderTwo /> */}
          <DashboardNavbar active={1}/>


          {/* About Area */}
          <DashboardOne />

          {/* Footer 2 */}
          <FooterTwo />
        </div>
      </Styles>
    );
  
}

export default Dashboard;
