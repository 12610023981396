import axios from "../axios";

const payment = {
  paymentPaypal(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post("paypal/processed_payment", data)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  paymentStripe(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post("stripe/processed_payment", data)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
  walletRecharge(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post("/add-money-to-wallet", data)
        .then(({ data }) => resolve(data))
        .catch((error) => reject(error));
    });
  },
};

export default payment;
