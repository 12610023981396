import React, { useState } from "react";
import { colors } from "./common/element/elements";
import { Styles } from "./styles/mobileNavBar";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useTranslation } from "react-i18next";

const MobileNavBar = ({
  open,
  setMobileNavBar,
  props,
  handleChangeLanguage,
  mode,
  setMode,
  currentLanguage,
  setIsOpen,
  setModal,
}) => {
  const { authUserData } = useSelector(
    ({ intialDataReducer }) => intialDataReducer
  );
  const { t } = useTranslation();
  return (
    <Styles>
      <div className="side-panel-container d-lg-none">
        {/* <div className="side-panel-outer"> */}
        <div className={`side-panel ${open} d-flex `}>
          <div className="panel-container col-8 ">
            <div className="d-flex mt-3 p-2 justify-content-between align-items-center ">
              <div className="d-flex gap-5 ">
                {/* ------Dark/Light Mode Block ---- */}
                <div
                  className=" d-flex justify-content-center align-items-center  "
                  onClick={() => handleChangeLanguage()}
                  style={{ cursor: "pointer" }}
                >
                  {currentLanguage === "en" ? (
                    <img
                      src={process.env.PUBLIC_URL + "/assets/images/en.webp"}
                      className="lang-img"
                      loading="lazy"
                      alt="langEn"
                      height={'100%'} width={'100%'}
                    />
                  ) : (
                    <img
                      src={process.env.PUBLIC_URL + "/assets/images/fr.webp"}
                      className="lang-img"
                      loading="lazy"
                      alt="langFr"
                      height={'100%'} width={'100%'}
                    />
                  )}
                </div>
                {/* -----Language Translation Block----- */}
                <div
                  className="dark-btn  justify-content-center align-items-center d-flex d-lg-none  "
                  onClick={() => setMode(mode === "dark" ? "light" : "dark")}
                  style={{ cursor: "pointer" }}
                >
                  {mode !== "dark" ? (
                    <Icon icon="ph:moon-duotone" color="#000000" width={"25"} />
                  ) : (
                    <Icon icon="ph:sun-duotone" color="yellow" width={"25"} />
                  )}
                </div>
                {/*------- Loggout Block -------  */}
                <div
                  className="justify-content-center align-items-center d-flex d-lg-none  "
                  onClick={() => {
                    setModal(2);
                    setIsOpen(true);
                    setMobileNavBar("closed")
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <Icon icon="mdi:sign-out" color="#cc0001" width={"25"} />
                </div>
              </div>
              <Icon
                icon="icon-park-twotone:close-one"
                className="align-self-end"
                color={colors?.orange}
                width={25}
                onClick={() => setMobileNavBar("closed")}
              />
            </div>
            <ul className="d-flex flex-column justify-content-around " style={{background:colors?.darkblue}}>
              <Link
                className={`nav-btn  ${props?.active === 1 ? "active" : ""}`}
                // onClick={() => setActive(1)}
                to={process.env.PUBLIC_URL + "/dashboard"}
              >
                {t("dashboard")}
              </Link>
              {authUserData?.userType == 2 ? (
                <Link
                  className={`nav-btn  ${props?.active === 2 ? "active" : ""}`}
                  // onClick={() => setActive(2)}
                  to={process.env.PUBLIC_URL + "/myclasses"}
                >
                  {t("myClasses")}
                </Link>
              ) : (
                <Link
                  className={`nav-btn  ${props?.active === 2 ? "active" : ""}`}
                  // onClick={() => setActive(2)}
                  to={process.env.PUBLIC_URL + "/myclasses"}
                >
                  {t("myClassesRequest")}
                </Link>
              )}
              {authUserData?.userType == 1 ? (
                <Link
                  className={`nav-btn  ${props?.active === 3 ? "active" : ""}`}
                  to={process.env.PUBLIC_URL + "/myadvert"}
                  // onClick={() => setActive(3)}
                >
                  {t("myAdvert")}
                </Link>
              ) : (
                <Link
                  className={`nav-btn  ${props?.active === 3 ? "active" : ""}`}
                  to={process.env.PUBLIC_URL + "/favourites"}
                  // onClick={() => setActive(3)}
                >
                  {t("favourites")}
                </Link>
              )}
              <Link
                className={`nav-btn  ${props?.active === 4 ? "active" : ""}`}
                to={process.env.PUBLIC_URL + "/myAccount"}
                // onClick={() => setActive(4)}
              >
                {t("myAccount")}
              </Link>

              <Link
                className={`nav-btn  ${props?.active === 5 ? "active" : ""}`}
                // onClick={() => setActive(2)}
                to={process.env.PUBLIC_URL + "/MyPayments"}
              >
                {t("myPayments")}
              </Link>
              {authUserData?.userType == 1 && (
                <>
                  <Link
                    className={`nav-btn  ${
                      props?.active === 6 ? "active" : ""
                    }`}
                    // onClick={() => setActive(2)}
                    to={process.env.PUBLIC_URL + "/Subscription"}
                  >
                    {t("UpgradeMembership")}
                  </Link>
                </>
              )}
            </ul>
          </div>
          <div
            className="col-4"
            onClick={() => setMobileNavBar("closed")}
          ></div>
        </div>
        {/* </div> */}
      </div>
    </Styles>
  );
};

export default MobileNavBar;
