import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  .home-blog-area {
    margin-top: 100px;

    .container {
      padding: 50px 11px 50px 11px;

      h4 {
        color: ${colors?.darkblue};
      }
      p {
        font-size: 12px;
      }
      .blog-btn {
        background: rgba(19, 107, 127, 0.3);
        border: 1px solid ${colors?.darkblue};
        border-radius: 25px;
        color: ${colors?.darkblue};
        font-weight: 500;
        cursor: pointer;
      }
      .blog-card-container {
          overflow: scroll;
          overflow-y:hidden;

        ::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
        }
        .blog-card {
          width: 260px;

          img {
            cursor: pointer;
            height: 190px;
            width: 260px;
            object-fit: cover;
          }
          .p1 {
            text-transform: uppercase;
            color: ${colors?.orange};
            font-weight: 500;
            font-size: 10px;
          }
          .p2 {
            color: ${colors?.darkblue};
            font-weight: 500;
            font-size: 16px;
            cursor: pointer;
            overflow: hidden;
            text-wrap: nowrap;
            text-overflow: ellipsis;
          }
          .p3 {
            font-weight: 500;
            font-size: 12px;
          }

         
        }
      }
    }
  }
`;
