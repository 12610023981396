import React, { Component, useState } from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import { Styles } from "./styles/exploreCourse";
import ReactStars from "react-rating-stars-component";
import { Icon } from "@iconify/react";
import { subjectGrade } from "../puglins/Utils/models";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import noNetworkAction from "../puglins/Utils/errorManage";
import { colors } from "./common/element/elements";

function ExploreCourse({
  subject,
  category,
  setCategory,
  getAllCategoryAdverts,
}) {
  const [option, setOption] = useState(1);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const history = useHistory();
  const { authUserData } = useSelector(
    ({ intialDataReducer }) => intialDataReducer
  );
  const [activeIndex, setActiveIndex] = useState(2);

  const getCourseBySubject = (id) => {
    subjectGrade
      .getAllAdvertsById(id)
      .then((res) => {
        setCategory(res?.data?.adverts);
        setOption(id);
      })
      .catch((error) => {
        error?.message === "Network Error" && noNetworkAction(history);
        console.log("Error while getting Data", error);
      });
  };
  return (
    <Styles>
      {/*Explorer Courses */}
      {subject?.length > 0 && (
        <section className="explorCourses">
          <Container>
            <Col md="12">
              <div className="sec-title d-flex justify-content-between align-items-center flex-column text-center text-md-start  flex-md-row ">
                <div>
                  <h4 className="mb-4 ">{t("exploreCourses")}</h4>
                  <p className="">{t("exploreCoursesPara")}</p>
                </div>
                <div
                  className={`right-block d-flex justify-content-around align-items-center p-1 px-1 text-black  flex-wrap    `}
                >
                  <div
                    className={`me-4 ${option === 1 && "active"}`}
                    onClick={() => {
                      setOption(1);
                      getAllCategoryAdverts();
                    }}
                  >
                    {t("allCategories")}
                  </div>
                  {subject?.map((item, index) => {
                    return (
                      <div
                        className={`${
                          index !== subject?.length - 1 && "me-4"
                        } ${option === item?.subject_id && "active"} `}
                        onClick={() => {
                          getCourseBySubject(item?.subject_id);
                        }}
                        key={item?._id}
                      >
                        {language === "en"
                          ? item?.subject_name
                          : item?.subject_name_fr}
                      </div>
                    );
                  })}

                  {/* <div
                  className={`me-4 ${option === 3 && "active"} `}
                  onClick={() => setOption(3)}
                >
                  Design
                </div>
                <div
                  onClick={() => setOption(4)}
                  className={`${option === 4 && "active"}`}
                >
                  Development
                </div> */}
                </div>
              </div>
            </Col>

            {/* <Carousel
              indicators={false}
              className="mt-4"
              prevLabel="p"
              prevIcon={
                <Icon
                  icon="ooui:previous-ltr"
                  width={25}
                  color={colors?.orange}
                  aria-label="prev"
                />
              }
              nextLabel="n"
              nextIcon={
                <Icon
                  icon="ooui:next-ltr"
                  width={25}
                  color={colors?.orange}
                  aria-label="next"
                />
              }
            >
              {category?.slice(0, 6)?.map((item, index) => {
                return (
                  <Carousel.Item className="">
                    <div
                      className="card-blk  border shadow-sm p-1 rounded-4 d-flex flex-column flex-md-row m-auto      "
                      key={index}
                    >
                      <div
                        className="img-blk d-flex justify-content-center align-items-center "
                        onClick={() => {
                          authUserData
                            ? history.push({
                                pathname: "/Teacher-Details",
                                state: {
                                  advertId: item?.advert?._id,
                                  teacherId: item?.teacherDetails?._id,
                                },
                              })
                            : history.push("/login");
                        }}
                      >
                        <img
                          src={item?.teacherDetails?.image}
                          alt="profile_pic"
                          width={196}
                          height={149}
                          className="rounded-4"
                          loading="lazy"
                        />
                      </div>
                      <div className="ms-lg-2 d-flex flex-column py-2 w-100   ">
                        <div className="border-bottom py-2  ">
                          <div className="text-warning d-flex align-items-center ">
                            <span className=" me-1 ">
                              {" "}
                              {item?.feedback?.average_rating.toFixed()}
                            </span>
                            <ReactStars
                              count={5}
                              size={18}
                              activeColor="#ffd700"
                              edit={false}
                              value={item?.feedback?.average_rating.toFixed()}
                            />
                            <span className="text-black-50 ms-1 ">
                              ({item?.feedback?.total_reviews})
                            </span>
                          </div>
                          <div className="heading">
                            {language === "en"
                              ? item?.advert?.subject_name
                              : item?.advert?.subject_name_fr}
                          </div>
                          <p>{item?.advert?.description}</p>
                        </div>
                        <div className="d-flex justify-content-between ">
                          <div>
                            <div className="name fw-bold ">
                              {item?.teacherDetails?.first_name
                                ?.charAt(0)
                                .toUpperCase() +
                                item?.teacherDetails?.first_name?.slice(1)}{" "}
                              {/* {item?.teacherDetails?.last_name
                                ?.charAt(0)
                                .toUpperCase() +
                                item?.teacherDetails?.last_name?.slice(1)} */}
                            {/* </div>
                            <div className="d-flex "> */}
                              {/* <div className="icon-blk d-flex align-items-center text-black-50  ">
                        <Icon icon="uil:book-open" className="me-1 " width={20} /> 5
                        Courses
                      </div> */}
                              {/* <div className="d-flex level-blk align-items-center text-success   ">
                                <div
                                  className="me-1  bg-success "
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                    borderRadius: "50%",
                                  }}
                                >
                                  {" "}
                                </div>{" "}
                                {item?.advert?.advert_level}
                              </div>
                            </div>
                          </div>
                          <div className="fw-bold name fs-5 ">
                            € {item?.advert?.hourly_rate}/
                            <span className="fs-6 fw-normal text-secondary  ">
                              hours
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                );
              })}
            </Carousel> */} 
            <div className="d-flex gap-5 card-container mt-3 p-4 ">
             {category?.slice(0, 6)?.map((item, index) => {
                return (
                    <div
                      className="card-blk shadow-sm p-1 rounded-4 d-flex flex-column flex-md-row m-auto col-md-11 col-lg-6 col-12    "
                      key={index}
                    >
                      <div
                        className="img-blk d-flex justify-content-center align-items-center "
                        onClick={() => {
                          authUserData
                            ? history.push({
                                pathname: "/Teacher-Details",
                                state: {
                                  advertId: item?.advert?._id,
                                  teacherId: item?.teacherDetails?._id,
                                },
                              })
                            : history.push("/login");
                        }}
                      >
                        <img
                          src={item?.teacherDetails?.image}
                          alt="profile_pic"
                          width={196}
                          height={149}
                          className="rounded-4"
                          loading="lazy"
                        />
                      </div>
                      <div className="ms-lg-2 d-flex flex-column py-2 w-100   ">
                        <div className="border-bottom py-2  ">
                          <div className="text-warning d-flex align-items-center ">
                            <span className=" me-1 ">
                              {" "}
                              {item?.feedback?.average_rating.toFixed()}
                            </span>
                            <ReactStars
                              count={5}
                              size={18}
                              activeColor="#ffd700"
                              edit={false}
                              value={item?.feedback?.average_rating.toFixed()}
                            />
                            <span className="text-black-50 ms-1 ">
                              ({item?.feedback?.total_reviews})
                            </span>
                          </div>
                          <div className="heading">
                            {language === "en"
                              ? item?.advert?.subject_name
                              : item?.advert?.subject_name_fr}
                          </div>
                          <p>{item?.advert?.description}</p>
                        </div>
                        <div className="d-flex justify-content-between ">
                          <div>
                            <div className="name fw-bold ">
                              {item?.teacherDetails?.first_name
                                ?.charAt(0)
                                .toUpperCase() +
                                item?.teacherDetails?.first_name?.slice(1)}{" "}
                             
                            </div>
                            <div className="d-flex ">
                              <div className="d-flex level-blk align-items-center text-success   ">
                                <div
                                  className="me-1  bg-success "
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                    borderRadius: "50%",
                                  }}
                                >
                                  {" "}
                                </div>{" "}
                                {item?.advert?.advert_level}
                              </div>
                            </div>
                          </div>
                          <div className="fw-bold name fs-5 ">
                            € {item?.advert?.hourly_rate}/
                            <span className="fs-6 fw-normal text-secondary  ">
                              {t('hours')}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                );
              })}
              </div>
          </Container>
        </section>
      )}
    </Styles>
  );
}

export default ExploreCourse;
