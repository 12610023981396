import React, { useEffect, useState, Suspense, lazy } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Loader from "../components/common/Loader";
import Dashboard from "../pages/dashboard/Dashboard";

// const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));
const Favourites = lazy(() => import("../pages/favourites/Favourites"));
const MyAccount = lazy(() => import("../pages/myAccount/MyAccount"));
const StudPublicProfile = lazy(() =>
  import("../pages/studPublicProfile/StudPublicProfile")
);
const SearchTeacher = lazy(() =>
  import("../pages/searchTeacher/SearchTeacher")
);
const TeacherDetails = lazy(() =>
  import("../pages/searchTeacher/TeacherDetails")
);
const TakeAClass = lazy(() => import("../pages/searchTeacher/TakeAClass"));
const MyClasse = lazy(() => import("../pages/myClasses/MyClasses"));
const MyPayments = lazy(() => import("../pages/Teacher/MyPayments/MyPayments"));
const ZoomMeeting = lazy(() => import("../components/ZoomMeeting"));
const PageNotFound = lazy(() => import("../pages/404/PageNotFound"));

function StudentRoutes() {
  const [access, setAccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const path = [
    "/dashboard",
    "/favourites",
    "/myclasses",
    "/myAccount",
    "/myPublicProfile",
    "/Take-A-Class",
    "/Teacher-Details",
    "/searchTeacher",
    "/MyPayments",
    "/ZoomClasses",
  ];
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    setAccess(path.includes(location.pathname));
    access !== null && setLoading(false);
  }, [location.pathname, access]);

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {!loading && access ? (
          <>
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/myclasses" component={MyClasse} />
            <Route path="/favourites" component={Favourites} />
            <Route path="/myAccount" component={MyAccount} />
            <Route path="/myPublicProfile" component={StudPublicProfile} />
            <Route path="/searchTeacher" component={SearchTeacher} />
            <Route path="/Teacher-Details" component={TeacherDetails} />
            <Route path="/Take-A-Class" component={TakeAClass} />
            <Route path="/MyPayments" component={MyPayments} />
            <Route path="/ZoomClasses" component={ZoomMeeting} />
          </>
        ) : (
          !loading && <Route component={PageNotFound} />
        )}
      </Switch>
    </Suspense>
  );
}

export default StudentRoutes;
