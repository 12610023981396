import React from 'react'
import { Styles } from './styles/loader'

function Loader(props) {
  return (
    <Styles>
   <div className='loader-main'>
        <span class="loader" ></span>
    </div>
    </Styles>
  )
}

export default Loader