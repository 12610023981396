import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  .footer2 {
    background: #a5d9ee;
    position: sticky;
    bottom: 0;
    width: 100%;
  }
  ul {
    list-style-type: none;
    line-height: 30px;
  }
  h5{
    color:${colors?.darkblue};
    height:40px;

     @media(max-width: 1000px) {
                font-size:17px;
                 height:auto;
            }
  }
  li {
    a {
      color: #000000;
      font-size: 14px;
      font-weight: 500;

      &:hover {
        color: ${colors?.orange};
      }
    }
   
  }
  .social-blk{
    background:#dadada;
    border-radius:50%;
}
.copytext-area{
  background:${colors?.darkblue};
}

`;
