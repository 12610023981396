import noNetworkAction from "../puglins/Utils/errorManage";
import { teacher } from "../puglins/Utils/models";

export const advertDataAction = (data) => {
  return {
    type: "ADVERT_DATA",
    payload: data,
  };
};

export const personalInfoAction = (data) => {
  return {
    type: "PERSONAL_INFO",
    payload: data,
  };
};

export const getMyAdvert = (history) => {

  return (dispatch) => {
    teacher
      .getAllAdvertByTeacher()
      .then((res) => dispatch({ type: "MYADVERTDATA", payload: res?.adverts }))
      .catch((error) =>{ error?.message==='Network Error'&&noNetworkAction(history); dispatch({ type: "MYADVERTDATA_ERROR", payload: error })});
  };
};
export const getDegree = (history,lang) => {
  return (dispatch) => {
    teacher
      .getDegree()
      .then((res) => {
        res?.map((item) => {
          item.label = lang==='en'?item?.degree_name:item?.degree_name_fr;
          item.value = item?._id;
        });
        dispatch({ type: "GET_DEGREE", payload: res });
      })
      .catch((error) =>{ error?.message==='Network Error'&&noNetworkAction(history); dispatch({ type: "GET_DEGREE_ERROR", payload: error })});
  };
};
export const getUniversity = (history,lang) => {
  return (dispatch) => {
    teacher
      .getUniversity()
      .then((res) => {
        res?.map((item) => {
          item.label = lang==='en'?item?.name:item?.name_fr;
          item.value = item?._id;
        });
        dispatch({ type: "GET_UNIVERSITY", payload: res });
      })
      .catch((error) =>{ error?.message==='Network Error'&&noNetworkAction(history); dispatch({ type: "GET_UNIVERSITY_ERROR", payload: error })});
  };
};
export const getAreaOfInterest = (history,lang) => {
  return (dispatch) => {
    teacher
      .getAreaOfStudy()
      .then((res) =>{
        res?.map((item) => {
          item.label = lang==='en'?item?.name:item?.name_fr;
          item.value = item?._id;
        });
        dispatch({ type: "GET_AOI", payload: res })})
      .catch((error) =>{ error?.message==='Network Error'&&noNetworkAction(history); dispatch({ type: "GET_AOI_ERROR", payload: error })});
  };

  
};
export const onGoingClassDetails=(data)=>{
  return{
    type:'ONGOING_CLASS',
    payload:data
  }
}
