import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/serviceBox.js";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory,Link } from "react-router-dom/cjs/react-router-dom.js";

function ServiceBox({become}) {
  const { t } = useTranslation();
  const { authUserData } = useSelector(
    ({ intialDataReducer }) => intialDataReducer
  );
  const history = useHistory();
  let data = {
    dataList: [
      {
        id: "1",
        boxIcon: "icon-park-twotone:time",
        title: t("advTitle"),
        subTitle: t("advPara"),
      },
      {
        id: "2",
        boxIcon: "material-symbols-light:verified-outline",
        title: t("advTitle2"),
        subTitle: t("advPara2"),
      },
      {
        id: "3",
        boxIcon: "fluent:hat-graduation-sparkle-28-regular",
        title: t("advTitle3"),
        subTitle: t("advPara3"),
      },
      {
        id: "4",
        boxIcon: "bxl:zoom",
        title: t("advTitle4"),
        subTitle: t("advPara4"),
      },
      {
        id: "5",
        boxIcon: "tdesign:undertake-transaction",
        title: t("advTitle5"),
        subTitle: t("advPara5"),
      },
      {
        id: "6",
        boxIcon: "fluent:person-support-28-filled",
        title: t("advTitle6"),
        subTitle: t("advPara6"),
      },
    ],
    dataListTeacher: [
      {
        id: "1",
        boxIcon: "fa:euro",
        title: t("bCardTitle"),
        subTitle: t("bCardDescrip"),
      },
      {
        id: "2",
        boxIcon: "mdi:lectern",
        title: t("bCardTitle2"),
        subTitle: t("bCardDescrip2"),
      },
      {
        id: "3",
        boxIcon: "fluent:hat-graduation-sparkle-28-regular",
        title: t("bCardTitle3"),
        subTitle: t("bCardDescrip3"),
      },
      {
        id: "4",
        boxIcon: "bxl:zoom",
        title: t("advTitle4"),
        subTitle: t("advPara4"),
      },
      {
        id: "5",
        boxIcon: "tdesign:undertake-transaction",
        title: t("advTitle5"),
        subTitle: t("advPara5"),
      },
      {
        id: "6",
        boxIcon: "fluent:person-support-28-filled",
        title: t("advTitle6"),
        subTitle: t("advPara6"),
      },
    ],
  };
  return (
    <Styles>
      {/* Service Box */}
      <section className="service-area mt-5  ">
        <Container>
          <Row>
            <Col md="12">
              <div className="sec-title text-center  ">
                <h4 className="mb-4 ">{t("avantagesAcadeclic")}</h4>
                <p className="text-white ">
                 {t('avantagesAcadeclicPara')}
                </p>
              </div>
            </Col>
            { (become?data.dataListTeacher:data.dataList).map(
              (data, i) => (
                <Col md="4" key={i}>
                  <div className="service-box d-flex p-2 align-items-center   ">
                    <div className="box-icon d-flex justify-content-center align-items-center col-4  ">
                      <Icon icon={data.boxIcon} width={30} height={30} />
                    </div>
                    <div className="box-title ms-3">
                      <h6>{data.title}</h6>
                      <p className=" ">{data.subTitle}</p>
                    </div>
                  </div>
                </Col>
              )
            )}
          </Row>
        </Container>
      </section>
      {/* --------Find the private tutor in one click */}
      <section className="private-tutor ">
        <Container>
          <Row>
            <Col md="12">
              {!become ? (
                <div className="find-blk d-flex align-items-center justify-content-center flex-column  ">
                  <h4 className="text-center">{t("advPara7")}</h4>
                  <button
                    className="my-4"
                    onClick={() =>
                      history.push(authUserData ? "/dashboard" : "login")
                    }
                    aria-label='btn-class'
                  >
                    {t("advbutton")}
                  </button>
                </div>
              ) : (
                <div className="find-blk text-center ">
                   {!authUserData && (
                  <Link to={process.env.PUBLIC_URL + "/registrationMain/1"}>
                  <button className={`my-4 ${become&&'w-auto '}`} aria-label='create tutor'>
                    {t('becomeButton')}
                  </button>
                  </Link>)}
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
}

export default ServiceBox;
