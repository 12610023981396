import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "rsuite/dist/rsuite-no-reset.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter as Router, Switch } from "react-router-dom";

// store.subscribe(() => console.log(store.getState()));
ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <App />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// serviceWorker.unregister();
